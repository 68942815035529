var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('nav-logged'):_vm._e(),_c('div',{staticClass:"search page grey lighten-4",class:{'mobile': _vm.$vuetify.breakpoint.smAndDown}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('v-col',[_c('h1',{staticClass:"pa-2 pb-0"},[_vm._v("Search")])]),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"mr-3",attrs:{"color":"black","dark":""},on:{"click":function($event){_vm.showFilter = !_vm.showFilter}}},[_vm._v("Filter")]):_vm._e()],1)],1),_c('v-container',{staticClass:"align-start pt-0",attrs:{"fill-height":""}},[(!_vm.loadedInit)?[_vm._v(" loading... "+_vm._s(_vm.loadedInit)+" ")]:[_c('ais-instant-search',{class:{'showFilter': _vm.showFilter},attrs:{"search-client":_vm.client,"index-name":_vm.index,"initial-ui-state":_vm.initialUiState}},[_c('ais-search-box',{staticClass:"search-box",attrs:{"placeholder":"Search Names, skills, or companies...","show-loading-indicator":true,"autofocus":""}}),_c('ais-refinement-list',{attrs:{"attribute":"skills"},scopedSlots:_vm._u([{key:"noResults",fn:function(ref){
var query = ref.query;
return _c('span',{},[_vm._v(" No results for "),_c('q',[_vm._v(_vm._s(query))])])}}])}),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var query = ref.state.query;
var hits = ref.results.hits;
return _c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!hits.length),expression:"!hits.length"}],staticClass:"no-results pa-4"},[_c('p',[_vm._v("No results found for the query: "),_c('strong',[_vm._v(_vm._s(query))])]),_c('v-alert',{attrs:{"text":"","color":"primary","icon":"mdi-bell-outline"}},[_c('p',[_vm._v("Would you like to be notified via email when an expert becomes meetable for \""+_vm._s(query)+"\"?")]),_c('hr',{staticStyle:{"opacity":".5"}}),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary"}},[_vm._v("Notify Me")])],1)],1)}}])}),_c('ais-hits',{scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return _c('div',{on:{"click":function($event){return _vm.goTo(item)}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"start","no-wrap":""}},[_c('v-col',{staticClass:"mr-2 mr-md-0",attrs:{"cols":"auto"}},[_c('div',{staticClass:"avatar rounded"},[_c('img',{attrs:{"src":_vm.$isUndefined(item.thumb) ? _vm.avatar : item.thumb}})])]),_c('v-col',{attrs:{"cols":"auto","sm":"auto","md":"auto"}},[_c('strong',{staticClass:"name"},[_vm._v(_vm._s(item.fullName))])])],1),_c('p',{staticClass:"skills pt-2 text--secondary",domProps:{"innerHTML":_vm._s(item.skills.join(', '))}})],1)}}])})],1)]],2)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('nav-logged-mobile'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }