<template>
  <div class="template">
    <nav-logged v-if="$vuetify.breakpoint.mdAndUp"></nav-logged>
    <div class="search page grey lighten-4" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <v-row no-gutters align="center" justify="space-between">
        <v-col>
          <h1 class="pa-2 pb-0">Search</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn class="mr-3" @click="showFilter = !showFilter" color="black" dark v-if="$vuetify.breakpoint.smAndDown">Filter</v-btn>
        </v-col>
      </v-row>
      <v-container fill-height  class="align-start pt-0">
        <template v-if="!loadedInit">
          loading... {{loadedInit}}
        </template>
        <template v-else>
          <!-- <v-text-field
          solo
          ref="search"
          :placeholder="searchKind === 'skills' ? 'Baking, Yoga, Presentations, etc..' : 'Name, Skills, Company, etc.'"
          prepend-inner-icon="mdi-magnify"
          :search-input.sync="search">
        </v-text-field> -->

          <ais-instant-search
          :search-client="client"
          :index-name="index"
          :class="{'showFilter': showFilter}"
          :initial-ui-state="initialUiState"
          >
            <ais-search-box
            class="search-box"
            placeholder="Search Names, skills, or companies..."
            :show-loading-indicator="true"
            autofocus
            />
            <ais-refinement-list
            attribute="skills">
            <span slot="noResults" slot-scope="{ query }">
              No results for
               <q>{{ query }}</q>
             </span>
            </ais-refinement-list>
            <ais-state-results>
              <v-card class="no-results pa-4"
                slot-scope="{ state: { query }, results: { hits } }"
                v-show="!hits.length"
              >
                <p>No results found for the query: <strong>{{ query }}</strong></p>
                <v-alert
                  text
                  color="primary"
                  icon="mdi-bell-outline"
                >
                      <p>Would you like to be notified via email when an expert becomes meetable for "{{query}}"?</p>
                      <hr style="opacity:.5">
                      <v-btn class="mt-4" color="primary">Notify Me</v-btn>


                </v-alert>

              </v-card>
            </ais-state-results>
            <ais-hits>
              <div class="" slot="item" slot-scope="{ item }" @click="goTo(item)">
                <v-row no-gutters align="center" justify="start" no-wrap>
                  <v-col cols="auto" class="mr-2 mr-md-0">
                    <!-- <v-avatar><img :src="$isUndefined(item.thumb) ? avatar : item.thumb"></v-avatar> -->
                    <div class="avatar rounded">
                      <img :src="$isUndefined(item.thumb) ? avatar : item.thumb" >
                    </div>
                  </v-col>
                  <v-col cols="auto" sm="auto" md="auto">
                    <strong class="name">{{item.fullName}}</strong>
                  </v-col>
                </v-row>
                <p class="skills pt-2 text--secondary" v-html="item.skills.join(', ')"></p>
              </div>

            </ais-hits>

          </ais-instant-search>

        </template>
       </v-container>
     </div>
     <!-- if mobile -->
     <nav-logged-mobile v-if="$vuetify.breakpoint.smAndDown"></nav-logged-mobile>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
const navLogged = () => import("@/components/shared/nav-logged.vue");
const navLoggedMobile = () => import("@/components/shared/nav-logged-mobile.vue");

export default {
  name: 'search',
  // props:['initVal', 'initField','placeholder', 'kind'],
  components:{navLogged, navLoggedMobile},
  watch:{
    search (query) {
      let vThis = this;
        // Items have already been loaded
        // if (this.items.length > 0) return
        vThis.isLoading = true

        process.env.NODE_ENV === "development" ? console.log( 'search query', query ) : null;
        vThis.index.search(query, vThis.requestOptions)
        .then(({ hits }) => {
          console.log(hits);
          vThis.items = hits;
          vThis.isLoading = false;
        });
      },
    //   searchQuery: {
    //     handler:function(val){
    //       if(!this.$isUndefined(val)){
    //         process.env.NODE_ENV === "development" ? console.log( 'query val........', val ) : null;
    //         this.search = val
    //       }
    //     },
    //     immediate:true
    // }
  },
  data(){
    return{
    model :null,
    avatar: "https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Transparent&clotheColor=Gray02&clotheType=Hoodie&eyeType=Happy&eyebrowType=UpDownNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheMagnum&graphicType=Resist&hairColor=BrownDark&hatColor=Gray01&mouthType=Grimace&skinColor=Pale&topType=ShortHairShortWaved",
    search:null,
    showFilter: false,
    items: [],
    isLoading:false,
    loadedInit: false,
    index: 'prod_PEOPLE',
    client:null,
    requestOptions: null,
    focused:false,
    selects:['skills','people'],
    searchKind: 'skills',
    searchQuery: null,
    initialUiState:{
      'prod_PEOPLE':{
        query: this.$isUndefined(this.$route.query) ? '': this.$route.query.q
      }
    },
    // routing:{
    //   routeToState(routeState){
    //     return {query}
    //   }
    // }
  }},
  methods:{
    onFocus(){
      process.env.NODE_ENV === "development" ? console.log( event ) : null;
      this.focused = !this.focused;
    },
    setupSearch(val){
      this.client = algoliasearch('P1WZL4Y0AZ', 'dfa6463422ca736108276061d8783e71');
      // this.index = this.client.initIndex('prod_PEOPLE');
      this.loadedInit = true;
      if(!this.$isUndefined(this.$route.query)){
        this.searchQuery = this.$route.query.q;
        process.env.NODE_ENV === "development" ? console.log('this.$route.query', this.$route.query ) : null;
      }
      // if(val === 'people'){
      //   this.index = this.client.initIndex('prod_PEOPLE');
      //   // this.requestOptions = {
      //   //   headers: { 'X-Algolia-UserToken': 'user123' }
      //   // }
      // }
      // else{
      //   this.index = this.client.initIndex('prod_SKILLS');
      // }
    },
    goTo(item){
      process.env.NODE_ENV === "development" ? console.log( 'item clicked', item ) : null;
      this.$router.push(`/@${item.userName}`)
    },
    transformItems(item){
      process.env.NODE_ENV === "development" ? console.log( 'items', item ) : null;
    },
    closeModal(){
      this.$store.dispatch('launchSearch',false)
    },
    routing(){
      return {
        router:{
          stateMapping: {
            routeToState(uiState){
              return {query:this.searchQuery}
            }
          },
          routeToState(routeState){
            return {query: routeState.query.q}
          }
        }
      }
    }
  },
  created(){
    this.setupSearch();
    // let autocompleteInput = this.$refs.search.$refs.input
    //  autocompleteInput.addEventListener('focus', this.onFocus, true)
    // autocompleteInput.addEventListener('blur', this.onFocus, true)
  },
  beforeRouteUpdate (to, from, next) {
    this.searchQuery = to.query.q;
    next();
    // next(vm => {
    //   alert('things')
    //   vm.loadUser(to.params.userName)
    // })
  }
};

</script>

<style lang="scss">
  .search.page{
    .avatar img{
      // max-width: 44px;
    }
    .ais-InstantSearch{
      display: grid;
      grid-template-columns: 25% 75%;
      grid-template-rows: 64px 1fr;
      grid-template-areas:
      "search search"
      "refinement results";
      width: 100%;
      .search-box{
        grid-area:search;
        display: block;
        // background: white;
        padding:5px;
        width:100%;
        flex-wrap: wrap;
        input{
          width: 100%;
          max-width: 620px;
        }
        border-bottom: 1px solid rgba(0,0,0,.1);
      }
      .ais-RefinementList{
        grid-area:refinement;
        padding-top: 15px;
      }
      .ais-StateResults{
        grid-area: results;
      }
      .no-results{
        width:100%;
        margin-top: 20px;
      }
      .ais-Hits{
        grid-area: results;
        padding-top: 15px;
        .ais-Hits-list{
          padding: 0;
          margin:0;
        }
        .ais-Hits-item{
          background: white;
          margin:0 1vw 15px 0;
          width:30%;
          padding:0;
          .avatar{
            overflow: hidden;
            width:100%;
            img{
              max-width: 100%;
            }
          }
          .name{
            display: inline-block;
            font-size: 16px;
            line-height: 18px;
            padding: 0 10px;
          }
          .skills{
            padding: 0 10px;
          }
        }
      }
    }
  }
  .mobile.search.page{
    .ais-InstantSearch{
      display: grid;
      grid-template-columns: 1fr 100px;
      grid-template-rows: 64px 1fr;
      grid-template-areas:
      "search search"
      "results results";
      width: 100%;
      &.showFilter{
        grid-template-areas:
        "search search"
        "results results";
      }
      .search-box{
        grid-area:search;
      }
      .ais-RefinementList{
        grid-area:refinement;
        display: none;
        overflow: hidden;
      }
      .ais-StateResults{
        grid-area:results;
      }
      .no-results{
        width:100%;
      }
      .ais-Hits{
        grid-area: results;
        padding-top: 20px;
        .ais-Hits-list{
          padding: 0;
          margin:0;
          .ais-Hits-item{
            background: white;
            margin:0 1vw 15px 0;
            padding:10px 5px;
            width: 100%;
            .avatar{
              overflow: hidden;
              width:60px;
              height: 60px;
              img{
                width: 60px;
              }
            }
            .name{
              display: inline-block;
              font-size: 24px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
</style>
